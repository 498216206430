import { useEffect, useState, useContext } from "react";
import { checkForFBPermissions, loginWithFB, queryFB } from "../../helpers/FB";
import useStyles from "./styles"
import { ConnectModalsContext } from "Hooks/ConnectModalsProvider"
import { CreativeTabConnectTypes } from "types/global";
import { track } from "analytics";
import { CurrentBrandContext } from "Hooks/CurrentBrandContext";
import { AccountContainerIg } from "components/AccountContainerIg";
import DialogPaper from "components/shareable/DialogPaper";
import HeaderModal from "./connectModals/Spotify/Components/HeaderModal";
import { FooterModal } from "components/FooterModal";
import { DialogContent } from "@material-ui/core";
import { LoadingLottie } from "components/Loader/LoadingLottie";
import useMediaBreakpoints from "Hooks/useMediaBreakpoints";
import { ConnectionsContext } from "pages/post-auth/MarketingPage/hooks/ConnectionsContext";
import { updateInBrand } from "services/symphonyApi/brandService";
import { FbIgPageOption, formatFacebookPages } from "./utils";
import { TextField } from '@material-ui/core';
import { ReactComponent as SearchIcon } from "assets/images/search-icon-2.svg";
import { UnloggedView } from "pages/post-auth/MarketingPage/Components/ConnectionsSection/UnloggedView";
import { AccountContainer } from "components/AccountContainer";

enum ERROR_STATES_STATUSES {
    NONE,
    PERMISSIONS_ERROR,
    NO_PAGES_ERROR
}
const ERROR_STATES = {
    PERMISSIONS_ERROR: {
        message: "We can't load your Facebook Pages because we don't have permission. Click the button below to authorize access to your pages."
    },
    NO_PAGES_ERROR: {
        message: "It looks like you don't have any Facebook pages associated with your account. Please create a new Facebook page by following the instructions at the link."
    }
}
export default function CampaignCreationFacebookConnect(props: any) {
    const {
        open,
        closeModal,
        showSave,
        localSave,
    } = props

    const { saveData } = useContext(ConnectModalsContext) || {}
    const {
        loggedInFbUser,
        facebookPage: selectedFBPage,
        setFacebookPage: selectFBPage,
        setInstagramPage: selectIgPage,
        setValidateConnections,
        setInstagramPagesAvailable,
    } = useContext(ConnectionsContext)
    const { currentBrand: artist, reloadBrand } = useContext(CurrentBrandContext);

    const classes = useStyles();
    const { mobileView } = useMediaBreakpoints();

    const [searchStr, setSearchStr] = useState('');
    const [facebookPages, setPages] = useState<FbIgPageOption[]>([])
    const [checkedPages, setCheckedPages] = useState<boolean>(false)
    const [loadingPages, setLoadingPages] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<boolean>(false)
    const [permissionsError, setPermissionsError] = useState<ERROR_STATES_STATUSES>(ERROR_STATES_STATUSES.NONE)

    const isDisabledConnectButton = error || !selectedFBPage

    useEffect(() => {
        if (open) {
            setError(false)
            getPages()
        }
    }, [open]);

    const getPages = async () => {
        if (!loggedInFbUser) return
        setCheckedPages(true)
        setLoadingPages(true)
        try {

            const results = await queryFB(`/me/accounts`, {
                params: {
                    fields: 'name,category,id,access_token,followers_count,picture{url},is_published',
                    limit: 200,
                    access_token: loggedInFbUser?.access_token
                }
            })

            const fbPages = await formatFacebookPages(results, loggedInFbUser?.access_token)
            setPages(fbPages);
        } catch (e) {
            track('Error pulling Facebook - getPages in Facebook Connector', e)
            setError(true)
            setPages([])
        }
        setLoadingPages(false)
    }

    async function checkPermissions() {
        const status: any = await checkForFBPermissions([
            'pages_show_list',
            'pages_read_engagement',
            'instagram_basic',
            'instagram_manage_insights',
            'ads_management',
            'ads_read'
        ], loggedInFbUser?.access_token)
        if (!status || status.length > 0) {
            setPermissionsError(ERROR_STATES_STATUSES.PERMISSIONS_ERROR)
        } else {
            setPermissionsError(ERROR_STATES_STATUSES.NO_PAGES_ERROR)
        }
    }

    async function reRequestPermissions() {
        const p = await loginWithFB(("rerequest" as any), "pages_show_list,pages_read_engagement")
        if (p) {
            setError(false)
            setPermissionsError(ERROR_STATES_STATUSES.NONE)
            getPages()
        }
    }

    useEffect(() => {
        // if we checked the pages, and they're empty - there 2 things that may
        // have happened here: 
        /**
         * 1. the artist declined "ads_management" permission, which we'll need alongside
         * "pages_read_engagement" to get access
         * 2. the artist has no pages, and we need to tell them to setup a page
         */
        if (checkedPages && facebookPages.length === 0) {
            checkPermissions()
        }
    }, [facebookPages])

    const handleConnectFacebookPage = async (pages: FbIgPageOption) => {
        if (localSave) {
            selectFBPage(pages.fbAccount)
            setLoading(false)
            setValidateConnections(true)
            closeModal()
            return
        }
        try {
            await updateInBrand(artist!.id, artist!.slug!, {
                facebook_page: pages.fbAccount,
                logged_in_fb_user: null,
            }, 'facebook_page')
            selectFBPage(pages.fbAccount)

            if (pages.igAccount) {
                await updateInBrand(artist!.id, artist!.slug!, pages.igAccount, 'instagram_page')
                selectIgPage(pages.igAccount)
                setInstagramPagesAvailable(true)
            } else {
                setInstagramPagesAvailable(false)
                selectIgPage(undefined)
            }
            await reloadBrand()

        } catch (e) {
            console.error("Error connecting Instagram", e)
            selectIgPage(undefined)
            track('Error connecting Instagram - updateInBrand in Facebook Connector', {
                error: e
            })
        }
        setValidateConnections(true)
        closeModal()
    }

    useEffect(() => {
        saveData({
            loading,
            connectModalType: CreativeTabConnectTypes.FACEBOOK_CONNECT,
            isDisabledConnectButton,
        })
    }, [isDisabledConnectButton, selectedFBPage, loading])

    function handleNonError() {
        if (loadingPages) return <LoadingLottie black />

        if (permissionsError === ERROR_STATES_STATUSES.NONE) {
            return (
                <>
                    {facebookPages.length > 3 &&
                        <TextField
                            value={searchStr}
                            type="search"
                            variant="outlined"
                            fullWidth
                            className={classes.input}
                            placeholder="Search..."
                            onChange={(e: any) => setSearchStr(e.target.value)}
                            InputProps={{ startAdornment: (<SearchIcon height={24} width={24} className="mr-4" />) }}
                        />
                    }
                    <ul>
                        {facebookPages.length > 0 && facebookPages
                            .filter((a) => {
                                if (!(searchStr && searchStr !== "")) {
                                    return true
                                } else {
                                    return a.fbAccount.name.toLowerCase().includes(searchStr.toLowerCase())
                                }
                            })
                            .map((item) => {
                                const connected_instagram_account = item.igAccount?.connected_instagram_account
                                return (
                                    <div className="mb-2">
                                        <AccountContainer
                                            title={item?.fbAccount.name}
                                            subtitle={item?.fbAccount.followers_count ? `${item?.fbAccount.followers_count} Followers` : ""}
                                            iconImg={item?.fbAccount.picture?.data?.url || connected_instagram_account?.profile_picture_url}
                                            instagramIcon={true}
                                            emptyConnection={true}
                                            onClick={async () => await handleConnectFacebookPage(item)}
                                            buttonText={selectedFBPage?.id === item.fbAccount.id ? "Connected" : "Use"}
                                            igLinked={connected_instagram_account?.username && connected_instagram_account?.username}
                                            disabledButton={selectedFBPage?.id === item.fbAccount.id}
                                        />
                                    </div>
                                );
                            })
                        }
                    </ul>
                    <FooterModal
                        footerText="Don’t see your Facebook or Instagram page listed? Make sure you've linked your Facebook profile with Symphony. If you still don't see it, you may need to get access to the page."
                        footerButton="Link a page to Symphony"
                        footerButtonOnClick={() => reRequestPermissions()}
                    />
                </>
            )
        } else if (permissionsError === ERROR_STATES_STATUSES.NO_PAGES_ERROR) {
            return (
                <>
                    <p className="my-4">{ERROR_STATES.NO_PAGES_ERROR.message}</p>
                    <a target="_blank" rel="noopener"
                        href="https://www.facebook.com/help/104002523024878/?helpref=uf_share" className="flex items-center justify-center w-full text-center p-4 text-primary font-bold border-t border-b border-backgound-100">
                        Create a New Page &gt;
                    </a>
                </>
            )
        } else if (permissionsError === ERROR_STATES_STATUSES.PERMISSIONS_ERROR) {
            return (
                <>
                    <p className="my-4">{ERROR_STATES.PERMISSIONS_ERROR.message}</p>
                    <button type="button"
                        onClick={() => reRequestPermissions()}
                        className="flex items-center justify-center w-full text-center p-4 text-primary font-bold border-t border-b border-backgound-100">
                        Add Facebook Pages Permissions &gt;
                    </button>
                </>
            )
        }

    }

    return (
        <DialogPaper
            open={open}
            fullScreen={Boolean(mobileView && loggedInFbUser)}
            dialogStyle={{
                margin: mobileView && loggedInFbUser ? 0 : 'auto 16px',
                borderRadius: mobileView && loggedInFbUser ? 0 : 8,
                maxWidth: 559,
            }}
            containerStyle={{
                margin: mobileView ? 0 : 'auto',
                padding: mobileView ? 8 : '32px 24px',
            }}
        >
            <DialogContent className="flex flex-col gap-8">
                {!loggedInFbUser ? (
                    <UnloggedView onClose={closeModal} />
                ) : (
                    <>
                        <HeaderModal
                            closeModal={closeModal}
                            title="Connect your Facebook Page"
                            subtitle="Select the Facebook Page and Instagram Page you want to use to run your ads. Your profile name will be shown in the ads."
                        />
                        <div className={classes.accountsContainer}>
                            <div className={`${localSave || showSave ? "middle-wrapper" : ""}`}>
                                {error ? (<p className="mt-4">No Facebook Pages found...</p>) : handleNonError()}
                            </div>
                        </div >
                    </>
                )}
            </DialogContent>
        </DialogPaper>
    )
}