import { FilteredList } from "components/shareable/FilteredList";
import LinkButton from "components/shareable/LinkButton";
import { Body2, Caption } from "components/shareable/Typography";
import { CURRENCY_CODE_DATA } from "helpers/currencies";
import millify from "millify";
import HeaderModal from "pages/post-auth/MarketingPage/Components/HeaderModal";
import { AdAccountDetail } from "./AdAccountDetail";
import { CreateAdAccountSection } from "./CreateAdAccountSection";
import { AD_ACCOUNT_STATUS, loginWithFB } from "helpers/FB";
import Logger from "Logger";
import { useState, useEffect, useContext } from "react";
import { toast } from "react-toastify";
import { AdAccountPageType, AdAccountStatuses, FacebookAdAccount } from "types/global";
import { getAdAccountsFromBusiness, connectAdAccount, TOAST_OPTIONS } from "./utils";
import { CurrentBrandContext } from "Hooks/CurrentBrandContext";
import { ReactComponent as LinkIcon } from "assets/images/external-link.svg";
import { ReactComponent as FacebookIcon } from "assets/images/facebook-rounded-icon.svg";
import { ConnectionsContext } from "pages/post-auth/MarketingPage/hooks/ConnectionsContext";
import { SystemColors } from "types/globalStyles";
import { useStyles } from "./styles";

const AccountStatuses = AD_ACCOUNT_STATUS as AdAccountStatuses;

interface ConnectAdAccountSectionProps {
  setupType: string;
  initCreateAdAccountProcess: boolean;
  onClose: () => void;
  setShowBusinessManagerSection: (show: boolean) => void;
  setInitCreateAdAccountProcess: (init: boolean) => void;
}

export const ConnectAdAccountSection: React.FC<ConnectAdAccountSectionProps> = ({
  setupType,
  initCreateAdAccountProcess,
  onClose,
  setShowBusinessManagerSection,
  setInitCreateAdAccountProcess,
}) => {
  const classes = useStyles();
  const { currentBrand, reloadBrand } = useContext(CurrentBrandContext);
  const { facebookAdAccount, setFacebookAdAccount, facebookBusinessManager, loggedInFbUser, setValidateConnections } = useContext(ConnectionsContext);

  const [adAccountOptions, setAdAccountsOptions] = useState<FacebookAdAccount[]>([]);
  const [adAccountsToShow, setAdAccountsToShow] = useState<FacebookAdAccount[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getAdAccounts()
  }, [])

  const reRequestPermissions = async () => {
    const response = await loginWithFB(("rerequest" as any), "ads_management,ads_read,business_management,pages_manage_ads")
    if (response) getAdAccounts()
  }

  const getAdAccounts = async () => {
    setLoading(true);
    const adAccounts = await getAdAccountsFromBusiness(setupType, facebookBusinessManager, loggedInFbUser?.access_token);
    setAdAccountsOptions(adAccounts);
    setAdAccountsToShow(adAccounts);
    setLoading(false);
  }

  const filterAdAccountsByName = (list: string[]) => {
    const selectedAdAccounts = adAccountOptions.filter(account => list.includes(account.name));
    setAdAccountsToShow(selectedAdAccounts);
  }

  const handleOnConnect = async (adAccount: FacebookAdAccount) => {
    let facebook_ad_account = JSON.parse(JSON.stringify(adAccount));
    localStorage.setItem('facebook_ad_account', JSON.stringify(facebook_ad_account))
    const updated = await connectAdAccount(adAccount, currentBrand)

    if (updated) {
      reloadBrand()
      setFacebookAdAccount(adAccount as unknown as AdAccountPageType)
      setValidateConnections(true)
      onClose()
      toast.success('Ad account successfully connected!', TOAST_OPTIONS)
    } else {
      Logger.error('Failed to connect ad account: ', adAccount)
      toast.error('There was an error trying to connect to your ad account', TOAST_OPTIONS)
    }
  }

  return (
    <div className='flex flex-col'>
      <div className="px-8 pt-6">
        <HeaderModal
          title="Facebook Ad Account"
          subtitle="Select the Facebook ad account you want to use to run your ads. If you don't have an ad account, you can create one by clicking the button below."
          closeModal={onClose}
        />
      </div>
      <div className="border-gray-100 w-auto border my-2" />
      <FilteredList
        list={adAccountOptions.map(account => account.name)}
        filterList={filterAdAccountsByName}
        loading={loading}
      >
        {adAccountsToShow.length === 0 && !loading ? (
          <div className={classes.emptySectionContainer}>
            <div className="p-2 rounded-lg" style={{ backgroundColor: SystemColors.PAPER_DIVIDER_CONTAINER_DARK }}>
              <FacebookIcon className={classes.FacebookDisabledIcon} />
            </div>
            <div className="flex flex-col gap-1 text-center">
              <Body2 color={SystemColors.PRIMARY_TEXT_COLOR}>We didn’t find any Ad Accounts linked to your profile</Body2>
              <Caption color={SystemColors.SECONDARY_TEXT_COLOR}>No ad account found, try to create a new one or read the documentation about how to connect.</Caption>
            </div>
          </div>
        ) : (
          <div className={classes.accountsContainer}>
            {adAccountsToShow.map((account) => {
              const currency = CURRENCY_CODE_DATA[account.currency];
              const statusName = account.account_status !== 1 ? AccountStatuses[account.account_status].name : undefined;
              return (
                <AdAccountDetail
                  key={account.id}
                  id={account.id}
                  title={account.name}
                  descriptions={[
                    `${currency.symbol_native}${millify(account.amount_spent)} spent`,
                    currency?.code !== 'USD' ? `This ad account uses ${currency.name_plural} (${currency.code})` : '',
                  ]}
                  connected={Boolean(facebookAdAccount?.id === account.id)}
                  onConnect={() => handleOnConnect(account)}
                  disabled={Boolean(account.account_status !== 1 || !account.userHasAccess || loading)}
                  statusName={statusName}
                  hasAccess={account.userHasAccess}
                />
              );
            })}
            {!loading && (
              <>
                <CreateAdAccountSection
                  onConnectAdAccount={handleOnConnect}
                  onShowBusinessManagerSection={() => setShowBusinessManagerSection(true)}
                  initCreateAdAccountProcess={initCreateAdAccountProcess}
                  finalizeCreateAdAccountProcess={() => setInitCreateAdAccountProcess(false)}
                />
                <div className='flex flex-col gap-4 mt-3'>
                  <Body2>Don't see your Facebook Ad Account listed? Make sure you've linked your ad account with Symphony by clicking below. If you still don't see it, you may need to create a new ad account.</Body2>
                  <LinkButton text='Link an Ad Account to Symphony' onClick={reRequestPermissions} rightIcon={<LinkIcon height={18} width={18} />} />
                </div>
              </>
          )}
          </div>
        )}
      </FilteredList>
    </div>
  );
};