import {
    Dispatch,
    SetStateAction,
    useEffect,
    useState,
    ChangeEvent,
    useMemo,
    FunctionComponent,
} from "react"
import {
    ArtistImage as ChannelImage,
    CloseButtonContainer,
    CloseIconContainer,
    ModalContainer,
    ModalMainTitleLabel,
    ModalPlatformInputContainer,
    ModalSubtitleLabel,
    ModalTitlesContainer,
    ModalTitlesLabelsContainer,
    PlatformLogoContainer,
    useStyles,
    SearchListContainer,
} from "../../../pages/post-auth/AddArtist/style"
import CloseIcon from "components/shareable/CloseIcon"
import { TextField, Dialog, Grid, InputAdornment, useTheme, useMediaQuery } from "@material-ui/core"
import { CurrentBrand, AudiomackArtistProfile } from "types/global"
import SymphonyLoadingLottie from "assets/images/lotties/SymphonyLoading.json"
import Lottie from "react-lottie"
import Axios from "helpers/Interceptor"
import isEqual from "lodash/isEqual"
import { track } from "analytics"
import { StyledLabel, TextButton } from "styles/shared"
import { ReactComponent as AudiomackIcon } from "assets/images/platforms/audiomack.svg";
import clsx from "clsx"
import { StyledButton } from "pages/post-auth/MarketingPage/Components/AddLocationModal/styles"

interface Props {
    closeModal: () => void;
    artist: CurrentBrand;
    selected: string | number | undefined | null;
    open: boolean;
    onSave?: () => void;
}

export async function queryAudiomackArtists(query: string) {
    try {
        const results = await Axios.get(`/audiomack/search/artists`, {
            params: {
                q: query
            }
        })

        return results.data
    } catch (error: any) {
        throw error
    }
}

const AudiomackConnectv2: FunctionComponent<Props> = ({
    closeModal,
    artist,
    selected,
    open,
    onSave
}: Props) => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down(421))
    const setResponsiveView = useMediaQuery(theme.breakpoints.down(701))
    const classes = useStyles()
    const [loading, setLoading] = useState<boolean>(false)
    const [textFieldValue, setTextFieldValue] = useState("")

    const [results, setResults] = useState<AudiomackArtistProfile[]>([])

    const [error, setError] = useState(false)
    const showConnectButton = !isEqual(results, [selected])


    // used to determine if the user submitted a query already
    const [userSubmittedSearch, setUserSubmittedSearch] = useState(false)

    const getModalContainerHeight = (profiles: number) => {
        if (profiles > 2) return "300px"
        return "100%"
    }

    const containerHeight = isMobile ? '100%' : getModalContainerHeight(results.length)



    async function pullResults(value: string) {
        setUserSubmittedSearch(true)

        if (!value.trim().length) {
            setResults([])
            return
        }

        setLoading(true);  // Assuming setLoading is a function to update a loading state

        let data = [];
        let error = null;

        try {
            const results = await queryAudiomackArtists(value);
            data = results?.data || [];
        } catch (e) {
            error = e;
        }


        if (error) {
            setError(true);
            setLoading(false);
        } else {
            setError(false);
            setResults(data.map((audiomackArtist: AudiomackArtistProfile) => ({
                ...audiomackArtist,
                id: audiomackArtist.id
            })));
            setLoading(false);

        }
        setUserSubmittedSearch(false)

    }

    useEffect(() => {
        if (results.length && !textFieldValue) {
            setResults([])
        }
    }, [textFieldValue])

    const updateInBrand = (channel: AudiomackArtistProfile) => {
        setLoading(true)
        if (artist) {
            track("Linked Audiomack Profile")
            Axios.put(`/brand/${artist.slug}/connect`, {
                id: artist.id,
                name: artist.slug,
                service: "audiomack",
                value: channel,
            })
                .then((_result) => {
                    onSave && onSave()
                    closeModal()
                    setError(false)
                    setLoading(false)
                })
                .catch((err) => {
                    console.log("error creating brand", err)
                    setError(true)
                })
        }
    }

    const handleOnClick = (profile: AudiomackArtistProfile) => () => {
        updateInBrand(profile)
    }

    const handleChangeTextField = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value
        setTextFieldValue(value)
    }

    useEffect(() => {
        setError(false)
        setLoading(false)
    }, [])


    function renderEndAdornment() {
        if (loading) {
            // Assuming LottieAnimation is a component or element that renders your Lottie animation
            return <InputAdornment position="end">
                <Lottie
                    height={24}
                    width={24}
                    options={{
                        loop: true,
                        autoplay: true,
                        animationData: SymphonyLoadingLottie,
                    }}
                />
            </InputAdornment>;
        }

        return (
            <TextButton
                onClick={() => pullResults(textFieldValue)}
            >
                Search
            </TextButton>
        )
    }


    let mainSubtitle = `Search for ${`${artist?.name}'s` || "your primary"} Audiomack profile.`
    let mainPlaceholder = "Bad Bunny, Jay-Z..."


    function getHelperText(args: {
        value: string
        error: boolean
        results: AudiomackArtistProfile[]
        loading: boolean
    }) {
        const { value, error, results, loading } = args


        if (error) return "An error occurred. Try again later."
        if (!results.length && value && !loading && userSubmittedSearch) {
            return `No artists found for "${textFieldValue}".`
        }
        return undefined
    }

    const helperText = getHelperText({
        value: textFieldValue,
        error,
        results,
        loading,
    })

    return (
        <Dialog
            open={open}
            BackdropProps={{ className: classes.modalBackground }}
            classes={{
                root: classes.rootDialog,
                scrollPaper: classes.scrollPaper,
            }}
            fullScreen={setResponsiveView}
        >
            <ModalContainer
                width={setResponsiveView ? "auto" : "678px"}
                margin={setResponsiveView ? "0px" : "auto"}
                height="100%"
                padding={setResponsiveView ? "16px" : "32px"}
                gap="32px"
            >
                <ModalTitlesContainer>
                    <CloseButtonContainer>
                        <CloseIconContainer onClick={closeModal}>
                            <CloseIcon />
                        </CloseIconContainer>
                    </CloseButtonContainer>
                    <ModalTitlesLabelsContainer>
                        <ModalMainTitleLabel>Connect Audiomack</ModalMainTitleLabel>
                        <ModalSubtitleLabel>
                            {mainSubtitle}
                        </ModalSubtitleLabel>
                    </ModalTitlesLabelsContainer>
                </ModalTitlesContainer>
                <ModalPlatformInputContainer>
                    <PlatformLogoContainer
                        width="48px"
                        height="48px"
                    >
                        <AudiomackIcon width="48px" height="48px" />
                    </PlatformLogoContainer>
                    <form style={{
                        width: '100%'
                    }}
                        onSubmit={(e) => {
                            e.preventDefault()
                            pullResults(textFieldValue)
                        }}>
                        <TextField
                            InputProps={{
                                classes: {
                                    root: classes.inputBorder,
                                },
                                endAdornment: renderEndAdornment(),
                            }}
                            value={textFieldValue}
                            onChange={handleChangeTextField}
                            className={classes.textField}
                            fullWidth
                            variant="outlined"
                            placeholder={mainPlaceholder}
                            helperText={helperText}
                            error={error}
                            FormHelperTextProps={{
                                classes: {
                                    root: classes.formHelperText,
                                },
                            }}
                        />
                    </form>
                </ModalPlatformInputContainer>
                {textFieldValue && !loading && (<SearchListContainer height={containerHeight}>
                    <Grid container direction="column">
                        {!loading && results.map((profile: AudiomackArtistProfile, index) => {
                            const {
                                id,
                                name,
                                thumbnail_url,
                                followers,
                                url_slug
                            } = profile
                            return (
                                <Grid
                                    item
                                    key={id}
                                    className={clsx(
                                        classes.channelItemRow,
                                        index === 0 && classes.dividerBottom,
                                        index !== 0 && index !== 1 && classes.dividerTop
                                    )}
                                >
                                    <Grid
                                        className="cursor-pointer"
                                        onClick={handleOnClick(profile)}
                                        spacing={showConnectButton && isMobile ? 2 : 3}
                                        container
                                        justifyContent="space-evenly"
                                        alignItems="center"
                                    >
                                        <Grid item className={classes.channelImage}>
                                            <ChannelImage
                                                widthStyle={setResponsiveView ? "48px" : "56px"}
                                                heightStyle={setResponsiveView ? "48px" : "56px"}
                                                alt={`${name}-url-image`}
                                                src={thumbnail_url}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            className={clsx(showConnectButton && isMobile && "w-24")}
                                            xs={showConnectButton && !isMobile || !showConnectButton}
                                        >
                                            <Grid container direction="column">
                                                <Grid item>
                                                    <StyledLabel fontWeight={500} fontSize={16}>
                                                        {name}
                                                    </StyledLabel>
                                                </Grid>
                                                <Grid item>
                                                    <StyledLabel
                                                        fontSize={12}
                                                        fontWeight={400}
                                                        color="#707070"
                                                    >
                                                        {followers.toLocaleString('en')} Follower{followers === 1 ? '' : 's'}
                                                    </StyledLabel>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        {showConnectButton && (
                                            <Grid item className={classes.connectButtonContainer}>
                                                <Grid container direction="row-reverse">
                                                    <StyledButton>Connect</StyledButton>
                                                </Grid>
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>
                            )
                        })}
                    </Grid>
                </SearchListContainer>)}
            </ModalContainer>
        </Dialog>
    )
}

export default AudiomackConnectv2