import SourcesTrends from 'components/Admin/SourcesGraph';
import { SourcesTable } from 'components/Admin/SourcesTable';
import { UserData } from 'types/global';


const SourcesPage = ({ user }: { user: UserData }) => {
    return(
        <div>
            <SourcesTable />
            <SourcesTrends user={user} type='signups' />
            <SourcesTrends user={user} type='logins' />
        </div>
    );
};

export default SourcesPage;
