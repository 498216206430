import { useState, useContext, useEffect } from "react";
import { Step, StepLabel, StepContent, Stepper } from "@material-ui/core";
import { CustomStepIcon } from "../../LinkClicks/styles";
import { Subtitle1, Body2 } from "components/shareable/Typography";
import FB, { loginWithFB } from "helpers/FB";
import { connectUserToFacebook } from "pages/post-auth/SettingsPage/Components/AccountTab/api";
import { AccountContainer } from "components/AccountContainer";
import { loggedInFb } from "../../../../../services/facebookApi/index";
import { VideoData, CONVERSIONS_VIDEOS } from "types/global";
import StepVideoContainer from "components/shareable/StepVideoContainer";
import useModal from "Hooks/useModal";
import { CurrentBrandContext } from "Hooks/CurrentBrandContext";
import { ConnectionsContext } from "../../hooks/ConnectionsContext";
import { useStyles } from "./styles";
import { FacebookMeResponse } from "types/global";
import { StepDetail } from "components/connect/StepDetails";
import { SystemColors } from "types/globalStyles";
import { ReactComponent as FacebookIcon } from "assets/images/facebook-rounded-icon.svg";
import { ReactComponent as InstagramIcon } from "assets/images/instagram-bordered-icon.svg";
import { ReactComponent as InstagramGrayIcon } from "assets/images/instagram-bw.svg";
import { CURRENCY_CODE_DATA } from "helpers/currencies";
import { millify } from "millify";
import LinkButton from "components/shareable/LinkButton";
import { LogoutModal } from "../LogoutModal";
import { disconnectAccounts } from "services/symphonyApi/brandService";
import AcceptFBTermsOfServiceCard from "components/shareable/AcceptFBTermsOfServiceCard";
import useTermsOfServiceTimer from "Hooks/useTermsOfServiceTimer";
import { toast } from "react-toastify";
import { TOAST_ERROR_OPTIONS } from "pages/post-auth/OrganizationPage/utils";
import { IgBanner } from "components/connect/IgBanner";
import Intercom from "helpers/Intercom";

interface StepsSocialMediaProps {
  handleShowFacebookModal: () => void;
  handleShowInstagramModal: () => void;
  handleShowAdAccountModal: () => void;
  selectedCampaignType?: string;
}
const { stepVideos } = CONVERSIONS_VIDEOS;

export const StepsSocialMedia = ({
  handleShowFacebookModal,
  handleShowInstagramModal,
  handleShowAdAccountModal,
  selectedCampaignType,
}: StepsSocialMediaProps) => {
  const classes = useStyles();
  const { currentBrand } = useContext(CurrentBrandContext);

  const {
    facebookAdAccount: selectedFBAdAccount,
    facebookPage: selectedFB_page,
    instagramPage: selectedInstaPage,
    conversionsEnabled,
    instagramPagesAvailable,
    preConnectionConfigurations,
    areTermsAccepted,
    needsToReLogIn,
    loggedInFbUser: me,
    setLoggedInFbUser: setMe,
    setPreConnectionConfigurations,
    removeConnections,
  } = useContext(ConnectionsContext);

  const { time, startTimer, handleStartTimer } = useTermsOfServiceTimer({ setPreconnectConfigurations: setPreConnectionConfigurations })
  const { id: brandId, name: brandName, slug: brandSlug } = currentBrand || {};
  const [loading, setLoading] = useState(false);
  const [triggerFacebookLogoutEvent, setTriggerFacebookLogoutEvent] = useState(false);
  const [currentVideo, setCurrentVideo] = useState<VideoData>(stepVideos.GETTING_STARTED);

  const watchVideoModal = useModal();
  const logoutModal = useModal();
  const currency = selectedFBAdAccount ? CURRENCY_CODE_DATA[selectedFBAdAccount.currency] : null;

  async function confirmFacebookLogout() {
    const authResp = FB().getAuthResponse();
    if (authResp && authResp.accessToken) {
      await new Promise((resolve) => {
        FB().logout(function (response: any) {
          resolve(1)
        });
      })
    }
    await disconnectAccounts(brandSlug!, brandId!, brandName!)
    setMe(undefined)
    removeConnections()
    setTriggerFacebookLogoutEvent(false)
    loggedInFb()
    logoutModal.closeModal()
    setLoading(false)
  }

  async function triggerFBLogin() {
    setLoading(true);
    try {
      await loginWithFB();
      await checkFBConnectionStatus();
    } catch (error) {
      console.error('error connecting FB: ', error)
      toast.error('Error logging in to Facebook', TOAST_ERROR_OPTIONS)
    }
  }

  async function checkFBConnectionStatus() {
    try {
      if (!brandId || !brandSlug || !brandName) return;

      setLoading(true);
      const userLoggedIn = await new Promise((resolve, reject) => {
        FB().getLoginStatus(function (response: any) {
          if (response.status === "connected") {
            resolve(true);
          } else {
            resolve(false);
          }
        });
      });

      if (userLoggedIn) {
        await connectUserToFacebook({
          brandId,
          brandSlug,
          brandName,
        });
        const profileData = await loggedInFb();
        setMe(profileData as FacebookMeResponse);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      throw new Error("Error connecting to Facebook");
    }
  }

  const handleOpenVideoModal = (video: VideoData) => {
    setCurrentVideo(video);
    watchVideoModal.openModal();
  };

  const sendErrorText = () => {
    if(selectedInstaPage && !selectedInstaPage?.businessAccount) {
      return "Not a business account"
    }

    return undefined
  }

  const hasFbPage = !selectedInstaPage && !instagramPagesAvailable && selectedFB_page
  const igIsNotBusiness = selectedInstaPage && !selectedInstaPage?.businessAccount

  return (
    <Stepper orientation="vertical" className={classes.stepper}>
      <Step active={true}>
        <StepLabel StepIconComponent={() => <CustomStepIcon />}>
          <Subtitle1>Facebook Profile</Subtitle1>
        </StepLabel>
        <StepContent>
          <div className="flex flex-col gap-2">
            <Body2>
              Connecting your Facebook profile lets Symphony automatically run ads using your selected page and ad account.
            </Body2>
            {/* TODO: Uncomment this when the video is ready */}
            {/* <LinkButton text="Learn more" onClick={() => handleOpenVideoModal(stepVideos.FB_PAGE)} /> */}
            {me ? (
              <>
                <AccountContainer
                  title={me?.name || ""}
                  subtitle=""
                  iconImg={me?.picture?.data.url}
                  buttonText="Log out"
                  onClick={async() => logoutModal.openModal()}
                  connectedChip
                  connectedText="Logged in"
                />
                <LogoutModal
                  open={logoutModal.open}
                  closeModal={() => logoutModal.closeModal()}
                  me={me}
                  logout={confirmFacebookLogout}
                />
              </>
            ) : (
              <AccountContainer
                title={!loading ? "Login to Facebook" : "Loading Facebook profile..."}
                subtitle=""
                buttonText="Connect"
                onClick={triggerFBLogin}
                fbIcon
                showSubtextIcon={false}
              />
            )}
            {needsToReLogIn && (
              <div className={classes.fbErrorContainer}>
                <div className="flex gap-2 items-center">
                  <FacebookIcon className={classes.fbErrorLogo} />
                  <Body2 color={SystemColors.PRIMARY_TEXT_COLOR}>It looks like your Facebook got disconnected from Symphony! We’ve saved your data - just click login to continue running ads.</Body2>
                </div>
                <LinkButton text="Learn more" onClick={() => Intercom.openFacebookSessionArticle()} />
              </div>
            )}
          </div>
        </StepContent>
      </Step>
      <Step active={true}>
        <StepLabel StepIconComponent={() => <CustomStepIcon />}>
          <Subtitle1>Ad Account</Subtitle1>
        </StepLabel>
        <StepContent>
          <div className="flex flex-col gap-2">
            <Body2>
              Your Facebook ad account is where you want Symphony to publish your ads. You'll be billed based on the billing method in your ad account.
            </Body2>
            {/* TODO: Uncomment this when the video is ready */}
            {/* <LinkButton text="Learn more" onClick={() => handleOpenVideoModal(stepVideos.AD_ACCOUNT)} /> */}
            <StepDetail
              showStepName={false}
              isCompleted={Boolean(selectedFBAdAccount)}
              stepName="Ad account"
              iconContainerColor={selectedFBAdAccount ? '#1977F329' : SystemColors.PRIMARY_DISABLE_CONTAINER}
              title={selectedFBAdAccount?.name || "Connect your Ad Account"}
              descriptions={[
                `${currency?.symbol_native}${millify(selectedFBAdAccount?.amount_spent || 0)} spent`,
                `This ad account uses the currency ${currency?.name_plural} (${currency?.code}).`,
              ]}
              onButtonClick={handleShowAdAccountModal}
              canEdit={!conversionsEnabled}
              tooltip={conversionsEnabled ? "You can't edit this connection while conversions are enabled" : undefined}
            />
            {selectedFBAdAccount && preConnectionConfigurations && !areTermsAccepted && (
              <AcceptFBTermsOfServiceCard
                timeStarted={startTimer}
                verifyingTime={time}
                onStartTimer={handleStartTimer}
              />
            )}
          </div>
        </StepContent>
      </Step>
      <Step active={true}>
        <StepLabel StepIconComponent={() => <CustomStepIcon />}>
          <Subtitle1>Facebook Page</Subtitle1>
        </StepLabel>
        <StepContent>
          <div className="flex flex-col gap-2">
            <Body2>
              Select the Facebook Page you want to use for your ads. It needs to be a public Facebook page, not a personal Facebook profile.
            </Body2>
            {/* TODO: Uncomment this when the video is ready */}
            {/* <LinkButton text="Learn more" onClick={() => handleOpenVideoModal(stepVideos.FB_PAGE)} /> */}
            <StepDetail
              isCompleted={Boolean(selectedFB_page)}
              stepName="FB Page"
              stepIcon={<FacebookIcon />}
              thumbnail={selectedFB_page?.picture?.data?.url}
              iconContainerColor={SystemColors.PRIMARY_DISABLE_CONTAINER}
              title={selectedFB_page?.name || "Connect your Facebook Page"}
              onButtonClick={handleShowFacebookModal}
            />
          </div>
        </StepContent>
      </Step>
      <Step active={true} className={classes.lastStep}>
        <StepLabel StepIconComponent={() => <CustomStepIcon />}>
          <Subtitle1>Instagram Page</Subtitle1>
        </StepLabel>
        <StepContent>
          <div className="flex flex-col gap-2">
            <Body2>
              This Instagram page will be the one seen by Instagram ad viewers. Your Instagram page needs to be connected to the Facebook page you've linked above.
            </Body2>
            {/* <LinkButton
              text="Video Walkthrough"
              onClick={() => handleOpenVideoModal(stepVideos.FB_PAGE)}
            /> */}
            <StepDetail
              isCompleted={Boolean(selectedInstaPage)}
              stepName="IG Page"
              stepIcon={selectedInstaPage ? <InstagramIcon /> : <InstagramGrayIcon />}
              thumbnail={selectedInstaPage?.instagramAccount?.profile_pic}
              iconContainerColor={SystemColors.PRIMARY_DISABLE_CONTAINER}
              title={selectedInstaPage?.instagramAccount?.username || "Connect your Instagram Page"}
              onButtonClick={handleShowInstagramModal}
              disabledButton={!selectedInstaPage && !instagramPagesAvailable}
              errorText={sendErrorText()}
            />
            {(hasFbPage || igIsNotBusiness) && (
              <IgBanner
                campaignType={selectedCampaignType}
                isNotBusiness={selectedInstaPage && !selectedInstaPage?.businessAccount}
              />
            )}
          </div>
        </StepContent>
        <StepVideoContainer
          {...currentVideo}
          openModal={watchVideoModal.open}
          onCloseModal={watchVideoModal.closeModal}
          onOpenModal={watchVideoModal.openModal}
          showDescription
          hideThumbnail
        />
      </Step>
    </Stepper>
  );
}
