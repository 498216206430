import { Grid } from "@material-ui/core";
import { Headline1, Subtitle2 } from "components/shareable/Typography";
import UpgradeToProLabel from "components/shareable/UpgradeToProLabel";
import BillingPlanOptions from "components/shareable/UpgradeToProModal/Components/BillingOptions";
import { BillingCycle, format, labels, teamLabels } from "components/shareable/UpgradeToProModal/utils";
import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { Container, StyledLabel, SymphonyTooltip } from "styles/shared";
import { SystemColors } from "types/globalStyles";
import { useStyles } from "./styles";
import PrimaryButton from "components/shareable/PrimaryButton";
import { getBrandPartnerDetails } from "helpers/partners";
import { CurrencyCodesType, OrganizationData, SpendBudgetData } from "types/global";
import { checkIfIsProTier } from "utils";
import getSymbolFromCurrency from "currency-symbol-map";
import { MONTHLY_PRO_PLAN_PRICE, MONTHLY_TEAM_PLAN_PRICE, MONTHLY_TEAM_PLAN_PRICE_EXTRA_USER, YEARLY_BY_MONTH_PRO_PLAN_PRICE, YEARLY_BY_MONTH_TEAM_PLAN_PRICE } from "../../../../../constants";
import { ReactComponent as QuestionMarkIcon } from "assets/images/question-mark.svg";
import Intercom from "helpers/Intercom";
import { getConvertedCurrency } from "services/symphonyApi";
import { DEFAULT_CURRENCY } from "../../constants";
import { getNumberWithDecimals } from "helpers/General";
import { CurrentBrandContext } from "Hooks/CurrentBrandContext";


type CurrencyPrices = {
    annualConverted: number,
    monthlyConverted: number,
}

export default function SubmitCampaignOrUpgrade(props: {
    creatingCampaign?: boolean | undefined;
    error: boolean;
    currencyCode: CurrencyCodesType;
    spendBudgetData: SpendBudgetData | null;
    handleClickUpgradeButton: () => void;
    handleClickSubmitButton: () => void;
    selectedBillingCycle: BillingCycle;
    setBillingCycle: Dispatch<SetStateAction<BillingCycle>>;
    mobileView?: boolean;
    primaryOrganization?: OrganizationData | null | undefined;
}) {
    const {
        creatingCampaign,
        error,
        handleClickUpgradeButton,
        handleClickSubmitButton,
        mobileView,
        selectedBillingCycle,
        setBillingCycle,
        currencyCode,
        primaryOrganization
    } = props

    const { currentBrand } = useContext(CurrentBrandContext);
    const isProUser = checkIfIsProTier(currentBrand)

    const classes = useStyles()


    const [currencyPrices, setCurrencyPrices] = useState<CurrencyPrices>({
        annualConverted: YEARLY_BY_MONTH_PRO_PLAN_PRICE,
        monthlyConverted: MONTHLY_PRO_PLAN_PRICE,
    })
    const [monthlyAditionalUserValue, setMonthlyAditionalUserValue] = useState<number>(MONTHLY_TEAM_PLAN_PRICE_EXTRA_USER)



    // UI shown when a user can successfully submit campaign
    function renderSubmissionUI() {
        return (
            <Grid item className={classes.noPaddingTop}>
                <StyledLabel color="#707070" fontSize={16} fontWeight={400}>
                    Once you submit your campaign, it will be sent to Facebook for
                    review, and begin running automatically once approved.
                </StyledLabel>
                <Grid container direction="column">
                    <div style={{
                        marginTop: '24px'
                    }}>

                        <PrimaryButton onClick={handleClickSubmitButton}
                            width="100%"
                            text="Submit Campaign"
                            loading={creatingCampaign} />


                    </div>

                </Grid>
            </Grid >
        )
    }

    // this is used ONLY for international currencies - specifically when a user selects an ad account with an international currency, and
    // we need to convert the "Upgrade to Pro" price to the international currency
    const getPricesValues = async () => {
        let baseMonthly;
        let baseAnnual;

        if (primaryOrganization && primaryOrganization.status === 'ready') {
            baseMonthly = await getConvertedCurrency({ from: DEFAULT_CURRENCY, to: currencyCode, amount: MONTHLY_TEAM_PLAN_PRICE })
            baseAnnual = await getConvertedCurrency({ from: DEFAULT_CURRENCY, to: currencyCode, amount: YEARLY_BY_MONTH_TEAM_PLAN_PRICE })
        } else {
            baseMonthly = await getConvertedCurrency({ from: DEFAULT_CURRENCY, to: currencyCode, amount: MONTHLY_PRO_PLAN_PRICE })
            baseAnnual = await getConvertedCurrency({ from: DEFAULT_CURRENCY, to: currencyCode, amount: YEARLY_BY_MONTH_PRO_PLAN_PRICE })
        }

        setCurrencyPrices({
            annualConverted: Number(getNumberWithDecimals(baseAnnual, 2)),
            monthlyConverted: Number(getNumberWithDecimals(baseMonthly, 2)),
        })
    }

    useEffect(() => {
        if (currencyCode !== DEFAULT_CURRENCY) {
            if (primaryOrganization && primaryOrganization.status === 'ready') {
                getConvertedCurrency({ from: DEFAULT_CURRENCY, to: currencyCode, amount: MONTHLY_TEAM_PLAN_PRICE_EXTRA_USER })
                    .then((res) => setMonthlyAditionalUserValue(Number(getNumberWithDecimals(res, 2))))
            }
            getPricesValues()
        } else {
            // TODO: remove this else statement when we fix the way we switch from brand to brand, right now it keeps some values unchanged
            if (currencyPrices.monthlyConverted !== MONTHLY_PRO_PLAN_PRICE) {
                setCurrencyPrices({
                    annualConverted: YEARLY_BY_MONTH_PRO_PLAN_PRICE,
                    monthlyConverted: MONTHLY_PRO_PLAN_PRICE,
                })
            }
            if (primaryOrganization && primaryOrganization.status === 'ready') {
                setCurrencyPrices({
                    annualConverted: YEARLY_BY_MONTH_TEAM_PLAN_PRICE,
                    monthlyConverted: MONTHLY_TEAM_PLAN_PRICE,
                })
            }
        }
    }, [currencyCode])


    function renderOrganizationUpgradeToProText() {

        // if a user is on a ready organization, there is no free trial opportunity + no partner-driven discount
        // - they either have a UI that lets them know the plan s included,
        // - or a UI to let them know they're purchasing an additional seat
        const isOverage = primaryOrganization?.status === 'ready' && primaryOrganization?.totalProBrands >= 5

        // get the currency codes
        const currencySymbol = getSymbolFromCurrency(currencyCode)


        // used to determine how to calculate price
        const fromPartner = getBrandPartnerDetails(currentBrand)

        // based on selected billing cycle, get the price
        const { price, billed, id: priceId, billedTrialText } = teamLabels[selectedBillingCycle]

        // the price, formatted properly for the international currency
        const formattedAmount = format(priceId === "monthly" ? currencyPrices.monthlyConverted : currencyPrices.annualConverted * 12)
        const formattedExtraSpend = format(monthlyAditionalUserValue)
        const formattedCurrency = currencySymbol
        // the time period (monthly or annual)
        const formattedTimePeriod = billedTrialText

        // to open Upgrade to Pro details article
        const openIntercom = () => Intercom.openSymphonyProArticle();

        let subtitleText;

        subtitleText = isOverage ? `Once you submit your campaign, your profile will be upgraded to Pro as part of your Team Plan: ${formattedCurrency}${formattedExtraSpend} / ${formattedTimePeriod}.` : 'Once you submit your campaign, your profile will be upgraded to Pro as part of your Team Plan.'

        return (
            <>
                <Container className={classes.noPaddingBottom}
                    display="flex" flexDirection="column"
                    gap="8px">


                    <Container display="flex" flexDirection="row" gap="8px" >
                        <UpgradeToProLabel
                            maxWidth="210px"
                            color="PURPLE"
                            label="Symphony for Teams"

                        />
                        <button onClick={openIntercom}>
                            <SymphonyTooltip
                                className="w-6 h-6"
                                title="Click here to learn more."
                                arrow
                            >
                                <QuestionMarkIcon height={24} width={24} />
                            </SymphonyTooltip>
                        </button>
                    </Container>
                    {subtitleText && (<Subtitle2 color={SystemColors.PRIMARY_TEXT_COLOR}>
                        {subtitleText}
                    </Subtitle2>)}
                    <StyledLabel color="#707070" fontSize={16} fontWeight={400}>
                        Once you submit your campaign, it will be sent to Facebook for
                        review, and begin running automatically once approved.
                    </StyledLabel>
                </Container>

                <Grid item className={classes.noPaddingTop}>
                    <Grid container direction="column">
                        <div style={{
                            marginTop: '24px'
                        }}>

                            <PrimaryButton
                                width="100%"
                                text={"Submit Campaign"}
                                onClick={handleClickSubmitButton}
                                loading={creatingCampaign}
                                disabled={error ? !error : creatingCampaign}
                            />

                        </div>

                    </Grid>
                </Grid>
            </>
        )
    }

    // UI shown when a user will need to upgrade to Pro
    function renderUpgradeToProText() {

        if (primaryOrganization && primaryOrganization.status === 'ready') {
            return renderOrganizationUpgradeToProText()
        } else {
            const freeTrialOver = currentBrand?.freeTrialOver

            // get the currency codes
            const currencySymbol = getSymbolFromCurrency(currencyCode)

            // used to determine how to calculate price
            const fromPartner = getBrandPartnerDetails(currentBrand)

            // based on selected billing cycle, get the price
            const { price, billed, id: priceId, billedTrialText } = labels[selectedBillingCycle]

            // either discountAmount, or 1x (no discount)
            const partnerDiscount: number = fromPartner && fromPartner.discountAmount ? fromPartner.discountAmount : 1

            // the price, formatted properly for the international currency
            const formattedAmount = format(priceId === "monthly" ? currencyPrices.monthlyConverted * partnerDiscount : currencyPrices.annualConverted * 12)
            const formattedCurrency = currencySymbol
            // the time period (monthly or annual)
            const formattedTimePeriod = billedTrialText

            // to open Upgrade to Pro details article
            const openIntercom = () => Intercom.openSymphonyProArticle();

            const headlineText = 'Upgrade to Pro to run AI-powered marketing campaigns.'

            let subtitleText;

            let showPartnerText;
            if (fromPartner) {
                switch (fromPartner.partnerId) {
                    case 'UM':
                        showPartnerText = `UnitedMasters SELECT artists `
                        break
                    case 'Audiomack':
                        showPartnerText = `Audiomack artists `
                        break
                    case 'CDBaby':
                        showPartnerText = `CDBaby artists `
                        break
                    case 'Downtown':
                        showPartnerText = `Downtown Music artists `
                        break
                }
                const partnerDiscountPercentage = 100 - (partnerDiscount! * 100)
                subtitleText = freeTrialOver ? `${showPartnerText} get a ${partnerDiscountPercentage}% discount on monthly plans for their first 12 months!` : `${showPartnerText} get a 14-day free trial + ${partnerDiscountPercentage}% discount on monthly plans for their first 12 months!`
            } else {
                subtitleText = freeTrialOver ? `Unlock the full power of Symphony for ${formattedCurrency}${formattedAmount} / ${formattedTimePeriod}.` : 'Start your 14-day free trial and unlock the full power of Symphony.'
            }

            return (
                <>
                    <Container className={classes.noPaddingBottom}
                        display="flex" flexDirection="column"
                        gap="8px">
                        <Container display="flex" flexDirection="row" gap="8px" >
                            <UpgradeToProLabel
                                maxWidth="160px"
                                color="PURPLE"
                                label="Symphony Pro"
                            />
                            <button onClick={openIntercom}>
                                <SymphonyTooltip
                                    className="w-6 h-6"
                                    title="Click here to learn more."
                                    arrow
                                >
                                    <QuestionMarkIcon height={24} width={24} />
                                </SymphonyTooltip>
                            </button>
                        </Container>
                        <Headline1 color={SystemColors.PRIMARY_TEXT_COLOR}>
                            {headlineText}
                        </Headline1>
                        {subtitleText && (<Subtitle2 color={SystemColors.PRIMARY_TEXT_COLOR}>
                            {subtitleText}
                        </Subtitle2>)}

                        <BillingPlanOptions
                            mobileView={mobileView}
                            billingCycle={selectedBillingCycle}
                            setBillingCycle={setBillingCycle}
                            partnerDiscount={partnerDiscount}
                            specificCurrencyCode={currencyCode}
                        />

                        <StyledLabel color="#707070" fontSize={16} fontWeight={400}>
                            Once you submit your campaign, it will be sent to Facebook for
                            review, and begin running automatically once approved. {!freeTrialOver && `Free trial is for 14 days, then ${formattedCurrency}${formattedAmount}${currencyCode !== 'USD' ? ` ${currencyCode}` : ``} / ${formattedTimePeriod} unless you cancel.  You\'ll get an email reminder 7 days before your trial ends.`}
                        </StyledLabel>
                    </Container>

                    <Grid item className={classes.noPaddingTop}>
                        <Grid container direction="column">
                            <div style={{
                                marginTop: '24px'
                            }}>
                                <PrimaryButton
                                    width="100%"
                                    text={!freeTrialOver ? "Start Free Trial & Submit Campaign" : "Upgrade & Submit Campaign"}
                                    onClick={handleClickUpgradeButton}
                                    loading={creatingCampaign}
                                    disabled={error ? !error : creatingCampaign}
                                />
                            </div>
                        </Grid>
                    </Grid>
                </>
            )
        }
    }
    return (
        <Container className={classes.space}>
            {isProUser ? (
                renderSubmissionUI()
            ) : renderUpgradeToProText()}


        </Container>
    )
}