import { useContext, useEffect, useState, useMemo } from "react";
import CloudinaryUploadWidget from "../../../../components/shareable/CloudinaryUploadWidget";
import { uploadAdVideo } from "../../../../helpers/Facebook";
import InfoSection from "../../../../components/InfoSection";
import Select from 'react-select'
import { InstagramPost } from "components/shareable/InstagramPosts/types";
import InstagramPosts from "components/shareable/InstagramPosts";
import { AssetUploadTypes, AssetUploadTypeSelector, containsExplicitContent, ExplicitContentWarning, loadInstagramPosts, UploadedAssetType, VideoUploader } from "../utils/fbCampaigns";
import { getRefreshInstagramPost, showNotFoundIGPostWarningToast } from "utils";

import { CreativeAssetTypes } from "../constants";
import { ConnectionsContext } from "../hooks/ConnectionsContext";
import { CurrentBrandContext } from "Hooks/CurrentBrandContext";
import { CampaignConnectionsDetails } from "components/connect/CampaignConnectionsDetails";
import { ConnectionsSetup } from "../Components/ConnectionsSection";
import { AdPreviewSection } from "../Components/AdPreviewSection";
import { LoadingLottie } from "components/Loader/LoadingLottie";

const { UPLOAD_VIDEO, INSTAGRAM_POSTS } = CreativeAssetTypes

interface LinkClicksCreativeTab {
    setSelectedAssets: any;
    selectedAssets: UploadedAssetType[] | null;
    setAddVisualSelected: any;
    setCaption: any;
    caption: any;
    setCTA: any;
    cta: any;
    selectedInstagramPost: any;
    setSelectedInstagramPost: any;
    saveUploadQueue?: (queue: Record<string, unknown>[] | null) => void;
    showContinueButton?: boolean;
    creativeSelectorTab: AssetUploadTypes;
    setCreativeSelectorTab: (type: AssetUploadTypes) => void;
    previewedVideo: UploadedAssetType | null;
    setPreviewedVideo: (type: UploadedAssetType | null) => void;
    isEventRegistered: boolean;
    selectedCampaignType: string;
    showPreview: boolean;
    enablePreviewMode: (show: boolean) => void;
}

export default function CreativeTab(props: LinkClicksCreativeTab) {
    const {
        creativeSelectorTab,
        setCreativeSelectorTab,
        setAddVisualSelected,
        previewedVideo,
        setPreviewedVideo,
        setSelectedAssets,
        selectedAssets,
        setCaption,
        caption,
        setCTA,
        cta,
        selectedInstagramPost,
        setSelectedInstagramPost,
        saveUploadQueue,
        showContinueButton = true,
        selectedCampaignType,
        showPreview,
        enablePreviewMode,
    } = props

    const { currentBrand: brand } = useContext(CurrentBrandContext)
    const {
        loggedInFbUser,
        instagramPage: selectedInstaPage,
        facebookAdAccount: selectedFBAdAccount,
        preConnectionConfigurations,
        areConnectionsValid: adConnectionsAdded,
        connectionsConfirmed: adConnectionsConfirmed,
        areTermsAccepted,
        conversionsEnabled: isConversionsEnabled,
        conversionsEventName: selectedConversionsEvent,
        setConnectionsConfirmed,
    } = useContext(ConnectionsContext)

    const { conversionsStatus } = brand || {}
    const { isError: showConversionsError } = conversionsStatus || {};
    const [uploadQueue, setUploadQueue] = useState<any>(null)
    const [LoadingPosts, setLoadingPosts] = useState(false)
    const [instagramPosts, setInstagramPosts] = useState<InstagramPost[]>([])

    const canShowAdPreview: boolean = useMemo(() => adConnectionsAdded && adConnectionsConfirmed, [adConnectionsAdded, adConnectionsConfirmed])

    const loadIGPosts = async () => {
        setLoadingPosts(true)
        if (selectedInstaPage) {
            const instagramPosts = await loadInstagramPosts({ instagramPage: selectedInstaPage, access_token: loggedInFbUser?.access_token })

            const hasInstagramPosts = Boolean(instagramPosts.length)
            const hasVideosUploaded = Boolean(selectedAssets?.length)

            if (hasInstagramPosts) {
                // map through IG posts, make sure they're not explicit
                const mappedIgPosts = instagramPosts.map((post: InstagramPost) => {
                    const caption = post.caption || ''
                    var explicitContent = false
                    if (caption) {
                        explicitContent = containsExplicitContent(caption)
                    }

                    return {
                        ...post,
                        explicit_content: explicitContent
                    }
                })

                setInstagramPosts(mappedIgPosts)

                if (hasVideosUploaded) {
                    setCreativeSelectorTab(UPLOAD_VIDEO)
                    setAddVisualSelected(UPLOAD_VIDEO)
                } else {
                    setCreativeSelectorTab(INSTAGRAM_POSTS)
                    setAddVisualSelected(INSTAGRAM_POSTS)
                    const refreshSelectedInstaPost = getRefreshInstagramPost(selectedInstagramPost, instagramPosts);
                    setSelectedInstagramPost(refreshSelectedInstaPost);
                }
            } else {
                setCreativeSelectorTab(UPLOAD_VIDEO)
                setAddVisualSelected(UPLOAD_VIDEO)
                setInstagramPosts([])
            }

            const igPostFound = showNotFoundIGPostWarningToast(instagramPosts, selectedInstagramPost)
            if (!igPostFound) setSelectedInstagramPost(null)

        } else {
            setCreativeSelectorTab(UPLOAD_VIDEO)
            setAddVisualSelected(UPLOAD_VIDEO)
            setInstagramPosts([])
        }
        setLoadingPosts(false)
    }

    useEffect(() => {
        if (saveUploadQueue) saveUploadQueue(uploadQueue)

        if (uploadQueue && uploadQueue.length > 0) {
            const { items } = uploadQueue
            var existing = selectedAssets ? selectedAssets : []
            existing = existing.concat(items).slice(0, 2)
            setSelectedAssets(existing)
            setUploadQueue(null)
            if (saveUploadQueue) saveUploadQueue(null)
        }
    }, [uploadQueue])

    const onClickCreativeSelectorTab = (evt: any) => {
        const tabClicked = evt.target.id;
        setCreativeSelectorTab(tabClicked);
        setAddVisualSelected(tabClicked);
    }

    async function uploadCreativeAssets(assets: Array<any>) {
        if (!selectedFBAdAccount) return

        var uploadedAssets = assets.map((asset: any) => {
            return new Promise(async (resolve: any, reject: any) => {
                const { url } = asset
                try {
                    const uploadedVideo = await uploadAdVideo(url, selectedFBAdAccount.id, loggedInFbUser?.access_token)
                    resolve({
                        ...asset,
                        fb_id: uploadedVideo.id,
                        type: 'video'
                    })
                } catch (e) {
                    alert("Error uploading video - " + asset.name + `\n${e}`)
                    console.error('e', e)
                    resolve(null)
                }
            })
        })

        let uploadedItems = await Promise.all(uploadedAssets)
        uploadedItems = uploadedItems.filter(o => o !== null)

        return uploadedItems
    }

    useEffect(() => {
        if ((selectedInstaPage && adConnectionsAdded) || (preConnectionConfigurations?.status && !instagramPosts.length)) {
            loadIGPosts()
        } else {
            setCreativeSelectorTab(UPLOAD_VIDEO)
            setInstagramPosts([])
            setSelectedInstagramPost(null)
        }
    }, [selectedInstaPage, adConnectionsAdded, loggedInFbUser])

    const [cloudinaryUploadQueue, setCloudinaryUploadQueue] = useState<Array<string>>([])

    function addToCloudinaryUploadQueue(fileId: string) {
        setCloudinaryUploadQueue((previous) => previous.concat(fileId));
    }

    function removeFromCloudinaryUploadQueue(fileId: string) {
        const newQueue = [
            ...cloudinaryUploadQueue
        ]

        const indexOfId = newQueue.indexOf(fileId)
        if (indexOfId > -1) {
            newQueue.splice(indexOfId, 1)

        }
        setCloudinaryUploadQueue(newQueue);
    }

    const successfulCloudinaryUpload = (file: any) => {
        const {
            info
        } = file

        const {
            id: fileId
        } = info

        removeFromCloudinaryUploadQueue(fileId)
        try {
            addToFacebookUploadQueue(file)
        } catch (e: any) {
            const errors = [...uploadErrors]
            errors.push(e)
            setUploadErrors(errors)
        }
    }

    const [facebookUploadQueue, setFacebookUploadQueue] = useState<Array<any>>(selectedAssets || [])
    const [queueToUpload, setQueueToUpload] = useState<Array<any>>([]);

    const [uploadErrors, setUploadErrors] = useState<Array<{
        name: string,
        message: string
    }>>([])

    function addToFacebookUploadQueue(file: any) {

        const {
            info,
        } = file

        const {
            thumbnail_url,
            asset_id,
            height,
            width,
            secure_url,
            original_filename: name,
            format

        } = info

        var sizeRatio = width / height

        //  - must be minimum with of 500px
        if (width < 500) {
            const error = new Error('Video must be wider than 500px.')
            error.name = `${name}.${format}`
            throw error
        }

        var type = 'feed'

        // IG Story Sizing:
        /**
         * 16/9 to 4/5
         * 9/16
         */

        // IG Feed Sizing:
        /**
         * 16/9 to 9/16
         */
        if (sizeRatio <= 16 / 9 &&
            sizeRatio >= 4 / 5) {
            type = 'feed'
        } else if ((sizeRatio <= 16 / 9 &&
            sizeRatio >= 4 / 5) || sizeRatio < 9 / 16 || sizeRatio >= 996 / 2156) {
            type = 'story'
        } else {
            const error = new Error('Video must be sized in an aspect ratio of 4x5 or 16x9 for feed, or 9x16 for story.')
            error.name = `${name}.${format}`
            throw error
        }

        // upload the asset

        const formattedValue = {
            thumbnail: thumbnail_url,
            cloudinary_id: asset_id,
            height: height,
            width: width,
            name: name,
            url: secure_url,
            formatType: type,
            uploading: true,
            fb_id: null
        }

        setQueueToUpload(previous => previous.concat(formattedValue))
        uploadToFb(formattedValue);
    }

    async function uploadToFb(file: any) {
        const {
            thumbnail,
            cloudinary_id,
            height,
            width,
            name,
            url,
            formatType,
        } = file

        // upload the asset
        let fbUploaded: any = await uploadCreativeAssets([
            {
                thumbnail,
                cloudinary_id,
                height,
                width,
                name,
                url,
                formatType
            }
        ])

        const fbUploadedValue = fbUploaded[0];
        setFacebookUploadQueue(previous => previous.concat(fbUploadedValue));
        setQueueToUpload((previous: any) => previous.filter((file: any) => file.cloudinary_id !== fbUploadedValue.cloudinary_id))
    }

    useEffect(() => {
        setSelectedAssets(facebookUploadQueue)
    }, [facebookUploadQueue])

    // Deletes an asset from the list
    function removeAsset(cloudinary_id: string) {
        const updatedWithoutAsset = selectedAssets?.filter((item: UploadedAssetType) => {
            return item.cloudinary_id !== cloudinary_id
        }) || []
        setFacebookUploadQueue(updatedWithoutAsset)
    }

    const showConnectionsSetup = !adConnectionsAdded || !adConnectionsConfirmed || showConversionsError || !areTermsAccepted || (isConversionsEnabled && !selectedConversionsEvent)
    const uploadingAssets = uploadQueue && uploadQueue.length > 0

    if (showConnectionsSetup) {
        return <ConnectionsSetup showConversions showEventSelector selectedCampaignType={selectedCampaignType}/>
    }

    return (
        <div className="flex gap-6">
            <div className="flex flex-col gap-4 w-full">
                <div className="rounded-lg bg-white">
                    <CampaignConnectionsDetails onEditConnections={() => setConnectionsConfirmed(false)} />
                </div>
                <div className="rounded-lg bg-white">
                    <InfoSection title="Add Creative Visuals" description="Upload the videos you want shown in your ads. We recommend uploading at least 1 feed asset (in a 4x5 sizing ratio, like 1080px by 1350px) and 1 story asset (in 9x16 sizing, like 1080px by 1920px)." />
                    <AssetUploadTypeSelector
                        instagramPage={selectedInstaPage}
                        selected={creativeSelectorTab}
                        onChange={onClickCreativeSelectorTab}
                    />
                    {LoadingPosts ? (
                        <div className="pt-6 pb-4">
                            <LoadingLottie black />
                        </div>
                    ) : creativeSelectorTab === INSTAGRAM_POSTS ? (
                        <div className="border-b pb-6">
                            <InstagramPosts
                                posts={instagramPosts}
                                setSelectedInstagramPost={setSelectedInstagramPost}
                                selectedInstagramPost={selectedInstagramPost}
                                showCaption={false}
                                allowPostPreviews={true}
                            />
                        </div>
                    ) : creativeSelectorTab === UPLOAD_VIDEO &&
                        <>
                            <div className="sy-card px-5 lg:px-10">
                                <VideoUploader
                                    maxAssets={3}
                                    uploadingAssets={uploadingAssets}
                                    selectedAssets={[...(selectedAssets || []), ...queueToUpload]}
                                    removeAsset={removeAsset}
                                    setPreviewedVideo={setPreviewedVideo}
                                    previewedVideo={previewedVideo}
                                />
                            </div>
                            {uploadErrors.length > 0 ? <div className="rounded-sm bg-yellow-500 text-white py-1 text-md py-3 px-3 text-center">
                                <p className="w-8/12 m-auto">There was an error uploading the following assets:</p>
                                {uploadErrors.map((o: {
                                    message: string,
                                    name: string
                                }) => {
                                    return (
                                        <p>{o.name}: {o.message}</p>
                                    )
                                })}
                                <button className="mt-3" onClick={() => {
                                    setUploadErrors([])
                                }}>Close</button>
                            </div> : null}
                        </>
                    }
                    <InfoSection title="Call-to-Action" description="Enter the caption you want to use for your ad, and what users should see as the swipe-up call-to-action on your ad." />
                    <div className={`px-6 lg:px-10 ${showContinueButton ? "py-2" : "pt-2 pb-8"} border-b`}>
                        {
                            creativeSelectorTab === UPLOAD_VIDEO ?
                                <>
                                    <p className="text-md font-medium mt-2">Caption</p>
                                    <p className="text-sm text-gray-500">Enter the caption you want people to see alongside your ad.</p>

                                    <textarea
                                        value={caption}
                                        onChange={(e) => setCaption(e.target.value)}
                                        placeholder={`e.g. Just dropped new merch! Check it out.`}
                                        className="rounded-md border w-full resize-none p-2 h-20 mt-2"

                                    ></textarea>
                                </>
                                : (selectedInstagramPost && selectedInstagramPost.caption) ?
                                    <>
                                        <p className="text-md font-medium mt-2">Caption</p>
                                        <p className="text-sm text-gray-500">Your Instagram post’s caption will be used for your ad.</p>

                                        <textarea
                                            value={selectedInstagramPost.caption} readOnly
                                            className="rounded-md border w-full resize-none p-2 h-20 mt-2 border-2 bg-gray-100 border-gray-200 resize-none"
                                        />
                                        {selectedInstagramPost.explicit_content && (
                                            <ExplicitContentWarning />
                                        )}
                                    </>
                                    : null
                        }

                        <p className="text-md font-medium mt-2">Call-to-Action</p>
                        <p className="text-sm text-gray-500">Select a call-to-action for your ad - when a user clicks this, they will be sent to your link.</p>

                        <Select
                            value={cta}
                            onChange={(value: any) => setCTA(value)}
                            options={[
                                { value: 'CONTACT_US', label: 'Contact Us' },
                                { value: 'DOWNLOAD', label: 'Download' },
                                { value: 'LEARN_MORE', label: 'Learn More' },
                                { value: 'SHOP_NOW', label: 'Shop Now' },
                                { value: 'SIGN_UP', label: 'Sign Up' },
                                { value: 'LISTEN_NOW', label: 'Listen Now' },
                                { value: 'WATCH_MORE', label: 'Watch More' }
                            ]}
                        />
                    </div>
                </div>
                <CloudinaryUploadWidget
                    onUploadAdded={(fileId: string) => addToCloudinaryUploadQueue(fileId)}
                    onUploadSuccess={successfulCloudinaryUpload}
                    onUploadAbortAll={() => {
                        setUploadQueue([])
                        if (saveUploadQueue) saveUploadQueue([])
                    }}
                    onUploadAbort={(fileId: string) => removeFromCloudinaryUploadQueue(fileId)}
                    uploadButtonId={"all_widget"}
                />
            </div>
            <AdPreviewSection
                canShowAdPreview={canShowAdPreview}
                captions={caption}
                cta={cta}
                creativeSelectorTab={creativeSelectorTab}
                enablePreviewMode={enablePreviewMode}
                previewedVideo={previewedVideo}
                selectedInstagramPost={selectedInstagramPost}
                showPreview={showPreview}
                uploadedImages={selectedAssets}
            />
        </div>
    )
}